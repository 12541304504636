<template>
  <section class="container690 training_detail">
    <iq-card body-class="profile-page p-0">
      <div class="basic_info">
        <div class="mb-2 title">{{ trainingInfo.training }}</div>
        <ul class="p-0 m-0 award_title">
          <li class="mb-1">
            <i style="font-weight: 600;" class="el-icon-unlock"></i>
            <span>Host Organization: {{ trainingInfo.organization }}</span>
          </li>
          <li class="mb-1">
            <i class="ri-t-box-line" style="line-height: 1;"></i>
            <span>Language: {{ trainingInfo.language }} </span>
          </li>
          <li class="mb-1">
            <i style="font-weight: 600;" class="el-icon-time"></i>
            <span>{{ trainingInfo.time_zone }}</span>
          </li>
          <li class="mb-1">
            <i style="font-weight: 600;" class="el-icon-date"></i>
            <span>Training Date: {{ trainingInfo.start_time }}</span>
          </li>
          <li class="mb-1">
            <i class="ri-calendar-event-line" style="line-height: 1;"></i>
            <span>Hours: {{ trainingInfo.duration }}</span>
          </li>
          <li class="mb-1">
            <i class="ri-briefcase-4-line" style="line-height: 1;"></i>
            <span>Type: {{ trainingInfo.type==1?'Online':'Offline' }}</span>
          </li>
          <li class="mb-1">
            <i class="ri-book-open-line" style="line-height: 1;"></i>
            <span>Knowledge Test: {{ trainingInfo.is_need_test?'YES':'NO' }}</span>
          </li>
          <li class="mb-1">
            <i class="ri-money-dollar-circle-line" style="line-height: 1;"></i>
            <span>Cost per Person: {{ trainingInfo.cost }}$</span>
          </li>
        </ul>
        <section class="trainPCbtn">
          <div v-if="!trainingInfo.course_registration_id&&trainingInfo.status==2" class="training_button register_btn"
            @click="registerTraining">Register
          </div>
          <div v-if="trainingInfo.course_registration_id" class="training_button register_btn registered_btn"
            @click="dialogVisible=true">Registered
          </div>
        </section>
        <div class="trainPhoneBtn">
          <div v-if="!trainingInfo.course_registration_id&&trainingInfo.status==2" class="training_button register_btn"
            @click="registerTraining">Register
          </div>
          <div v-if="trainingInfo.course_registration_id" class="training_button register_btn registered_btn"
            @click="dialogVisible=true">Registered
          </div>
        </div>
        <div class="circle_content">
          <i class="circle" :style="{background:{2:'#53c5b8',3:'#FFBA68',4:'#999'}[trainingInfo.status]}"></i>
          <span>{{{2:'Registration Open',3:'Training Started',4:'Training Closed'}[trainingInfo.status]}}</span>
        </div>
      </div>
    </iq-card>
    <!-- 选项卡 -->
    <iq-card body-class="profile-page p-0">
      <template v-slot:body>
        <tab-nav :tabs="true" id="myTab-1" class="tabsWidthScroll">
          <tab-nav-items :active="true" id="trainerContent" ariaControls="trainer_content" role="tab"
            :ariaSelected="true" title="Trainer Contact" />
          <tab-nav-items :active="false" id="address" ariaControls="address_id" role="tab" :ariaSelected="false"
            title="Address" v-if="trainingInfo.type==2||trainingInfo.course_registration_id" />
          <tab-nav-items :active="false" id="trainingMaterials" ariaControls="training_materials" role="tab"
            :ariaSelected="false" title="Training Materials" />
          <tab-nav-items v-if="trainingInfo.is_need_test==1&&trainingInfo.course_registration_id" :active="false"
            id="knowledgeTest" ariaControls="knowledge_test" role="tab" :ariaSelected="false" title="Knowledge Test" />
        </tab-nav>
        <tab-content id="myTabContent">
          <tab-content-item :active="true" id="trainer_content" aria-labelled-by="trainerContent">
            <section class="trainer_info">
              <div class="item d-flex mb-2 align-items-center" @click="toProfile(trainingInfo.trainer_user_id)"
                :class="trainingInfo.trainer_user_id?'cursor-pointer':''">
                <span class="left">
                  <el-avatar :size="45" :src="trainingInfo.trainer_avatar" fit="cover"></el-avatar>
                </span>
                <div class="right">{{trainingInfo.trainer}}</div>
              </div>
              <!-- <div class="item d-flex mb-2">
                <span class="left">Contact E-mail</span>
                <div class="right">{{trainingInfo.trainer_contact_email}}</div>
              </div>
              <div class="item d-flex mb-2">
                <span class="left">Phone</span>
                <div class="right">{{trainingInfo.trainer_mobile}}</div>
              </div> -->
              <div class="item d-flex mb-2">
                <span class="left">Host Organization</span>
                <div class="right">{{trainingInfo.trainer_organization}}</div>
              </div>
              <div class="item d-flex mb-2">
                <span class="left">Country</span>
                <div class="right">{{trainingInfo.trainer_country}}</div>
              </div>
              <!-- <div class="item d-flex mb-2">
                <span class="left">Gender</span>
                <div class="right">{{{0:'Female',1:'Male',2:'Prefer not to say',3:'Other'}[trainingInfo.gender]}}</div>
              </div> -->
            </section>
          </tab-content-item>
          <tab-content-item :active="false" id="address_id" aria-labelled-by="address">
            <section class="address_content">
              <div class="online" v-if="trainingInfo.type==1">
                <h5>Online Address :</h5>
                <el-card class="box_card">
                  <a :href="trainingInfo.link" class="d-flex align-items-center" target="_blank">
                    <i class="ri-vidicon-2-line" style="font-size: 24px;"></i>
                    <span class="ml-3">Online Link</span>
                  </a>
                </el-card>
              </div>
              <div class="online" v-else>
                <h5>Offline Address :</h5>
                <p class="mt-3">{{trainingInfo.address}}</p>
              </div>
            </section>
          </tab-content-item>
          <tab-content-item :active="false" id="training_materials" aria-labelled-by="trainingMaterials">
            <section class="training_materials" v-if="trainingInfo.materials&&trainingInfo.materials.length">
              <el-card class="materials_item" v-for="(v, i) in trainingInfo.materials" :key="i">
                <a :href="v.link" class="d-flex align-items-center" target="_blank">
                  <i class="ri-folder-5-fill" style="font-size: 18px;color: #EDBE38;"></i>
                  <span class="ml-2" style="color: #666;">{{v.name}}</span>
                </a>
              </el-card>
            </section>
            <section v-else style="background:#fff;" class="d-flex justify-content-center p-5">
              <img style="width:250px;" src="@/assets/images/group/empty1.png" alt="" />
            </section>
          </tab-content-item>
          <tab-content-item :active="false" id="knowledge_test" aria-labelled-by="knowledgeTest">
            <section class="knowledge_test">
              <el-card class="materials_item">
                <section class="d-flex justify-content-between align-items-center" v-if="trainingInfo.is_passed">
                  <a class="d-flex align-items-center">
                    <i class="ri-book-open-line" style="font-size: 24px"></i>
                    <span class="ml-2">Knowledge Test</span>
                  </a>
                  <div class="d-flex">
                    <span class="mr-3">{{trainingInfo.is_passed==1?'Passed':'Failed'}}</span>
                    <span :style="{color:trainingInfo.is_passed==1?'#67C23A':'#F56C6C'}">
                      {{trainingInfo.score}}
                    </span>
                  </div>
                </section>

                <section class="d-flex align-items-center cursor-pointer" v-else @click="toTest">
                  <a class="d-flex align-items-center">
                    <i class="ri-book-open-line" style="font-size: 24px"></i>
                    <span class="ml-2">Knowledge Test</span>
                  </a>
                </section>
              </el-card>
            </section>
          </tab-content-item>
        </tab-content>
      </template>
    </iq-card>

    <el-dialog title="Confirmation Reminder" :visible.sync="dialogVisible" width="580px">
      <span>Are you sure you want to cancel your registration on this training?</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Go Back</el-button>
        <el-button type="primary" @click="cancelRegister">Yes, Request Cancellation</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  export default {
    data() {
      return {
        trainingInfo: {},
        dialogVisible: false
      };
    },
    created() {
      this.getTrainingInfo()
    },

    methods: {
      // 去到讲师主页
      toProfile(user_id) {
        if (user_id) {
          this.$router.push(`/profile?user_id=${user_id}`)
        }
      },

      // 获取培训详情
      async getTrainingInfo() {
        let res = await this.$http.getTrainingInfo({ training_id: this.$route.query.id })
        if (res.status == 200) {
          this.trainingInfo = res.data;
        }
      },

      // 报名培训
      async registerTraining() {
        let res = await this.$http.registerTraining({ training_id: this.$route.query.id })
        if (res.status == 200) {
          this.getTrainingInfo()
        }
      },

      // 取消报名
      async cancelRegister() {
        let res = await this.$http.cancelRegister({
          training_id: this.$route.query.id,
          course_registration_id: this.trainingInfo.course_registration_id
        })
        if (res.status == 200) {
          this.getTrainingInfo()
          this.dialogVisible = false
        }
      },

      // ...去考试
      toTest() {
        this.$http.clickExam({ training_id: this.$route.query.id }).then(res => {
          if (res.status == 200) {
            this.$router.push(`/skill_test?id=${this.$route.query.id}&course_registration_id=${this.trainingInfo.course_registration_id}`)
          }
        })
      }

    },
  };
</script>
<style src="" lang="scss" scoped>
  .basic_info {
    position: relative;
    padding: 20px;

    .register_btn {
      background: #f5fbff;
      border: 1px solid #50b5ff;
      color: #50B5FF;
      cursor: pointer;
    }

    .registered_btn {
      background: #909399;
      border: 1px solid #909399;
      color: #f5fbff;
    }

    .circle_content {
      position: absolute;
      right: 20px;
      top: 20px;
      display: flex;
      align-items: center;

      .circle {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        margin-right: 5px;
      }

      span {
        margin-left: 6px;
        color: #666;
      }
    }
  }

  .training_detail {
    min-height: 100vh;
    position: relative;

    .title {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 26px;
    }

    .Share {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }

      .name {
        color: #333333;
        padding-right: 19px;
        margin-right: 19px;
        margin-left: 11px;
      }
    }

    .award_title {
      margin: 30px 0 10px !important;

      >li {
        margin-top: 3px;

        i {
          font-size: 18px;
          color: #333;
        }

        >span {
          color: #333;
          padding-left: 10px;
        }
      }
    }

    ::v-deep .nav-tabs .nav-link.active {
      color: #50B5FF !important;
    }

    .trainer_info {
      padding: 0 20px 20px;

      .left {
        width: 200px;
        color: #666;

        ::v-deep .el-avatar>img {
          width: 100%;
        }
      }

      .right {
        color: #999;
        flex: 1;

        ::v-deep p {
          margin: 0 !important;
          padding: 0 !important;
        }
      }
    }

    section.address_content {
      padding: 10px 20px 20px;

      .box_card {
        margin-top: 20px;
        height: 80px;
        display: flex;
        align-items: center;
        font-size: 18px;
      }
    }
  }

  .training_materials {
    padding: 10px 20px 20px;

    .materials_item {
      height: 45px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }

  .knowledge_test {
    padding: 0 20px 20px;
    margin-top: 20px;

    .materials_item {
      height: 80px;
      font-size: 18px;
    }
  }
  @media (min-width: 600px) {
    ::v-deep .nav-tabs .nav-item a {
      font-size: 16px !important;
    }
    .trainPhoneBtn {
        display: none;
      }
    .training_button {
      position: absolute;
      width: 200px;
      height: 40px;
      background: #eeeeee;
      border: 1px solid #dddddd;
      border-radius: 5px;
      text-align: center;
      line-height: 40px;
      font-weight: 500;
      color: #999;
      font-size: 16px;
      right: 20px;
      bottom: 30px;
    }
    .title {
      padding-right: 140px;
    }
  }
  @media (max-width: 600px) {
    ::v-deep .el-dialog{
      width: 100% !important;
      margin: 0 !important;
    }
    ::v-deep .nav-tabs .nav-item a {
      font-size: 13px !important;
    }
    .basic_info{
      .trainPCbtn {
        display: none;
      }
      .trainPhoneBtn{
        display: flex;
        justify-content: end;
      }
      .training_button {
        width: 200px;
        height: 40px;
        background: #eeeeee;
        border: 1px solid #dddddd;
        border-radius: 5px;
        text-align: center;
        line-height: 40px;
        font-weight: 500;
        color: #999;
        font-size: 16px;
      }
      .register_btn {
        background: #f5fbff;
        border: 1px solid #50b5ff;
        color: #50B5FF;
        cursor: pointer;
      }

      .registered_btn {
        background: #909399;
        border: 1px solid #909399;
        color: #f5fbff;
      }
      .title {
        max-width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
</style>